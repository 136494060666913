import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { debounce } from 'lodash'; 
import { useAppSelector, useAppDispatch } from './app/hooks';

import { setDevicePixelRatio, setViewPortHeight, setViewPortWidth } from './features/deviceSlice';


import PublicRoute  from './routes/PublicRoute'
import PrivateRoute from './routes/PrivateRoute';

// Public 
import RecentPage  from './pages/public/RecentPage';
// import OverviewPage from './pages/public/OverviewPage';
import GridPage from './pages/public/GridPage';
import PrivatePage from './pages/public/PrivatePage';
import ContactPage from './pages/public/ContactPage';
import { GridSize, ProjectStatus } from './types/_base';

import ContactPageA from './pages/contactPageOptions.tsx/ContactPageA';
import ContactPageB from './pages/contactPageOptions.tsx/ContactPageB';
import ContactPageC from './pages/contactPageOptions.tsx/ContactPageC';

const router = createBrowserRouter([
  {
    path: "/",
    element: <PublicRoute />,
    children: [
      { path: "/",            element: <GridPage activeProjectStatus={ProjectStatus.overview} additionalProjectStatuses={[ ProjectStatus.recent, ProjectStatus.archive ]}  gridSize={GridSize.medium} />  },
      { path: "/overview",    element: <GridPage activeProjectStatus={ProjectStatus.overview} additionalProjectStatuses={[ ProjectStatus.recent, ProjectStatus.archive ]}  gridSize={GridSize.medium} />  },
      { path: "/recent",      element: <RecentPage />   },
      { path: "/archive",     element: <GridPage activeProjectStatus={ProjectStatus.archive} additionalProjectStatuses={[ ProjectStatus.recent, ProjectStatus.overview]} gridSize={GridSize.medium} />  },
      { path: "/contact",     element: <ContactPage />  },
      
      { path: "/contact/A",     element: <ContactPageA />  },
      { path: "/contact/B",     element: <ContactPageB />  },
      { path: "/contact/C",     element: <ContactPageC />  },
    ],
  }, {
    path: "/private",
    element: <PrivateRoute />,
    children: [
      { path: "/private/:slug", element: <PrivatePage /> },
    ]
  },
]);

export function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleResize = debounce(() => {
      dispatch(setViewPortWidth(window.innerWidth));
      dispatch(setViewPortHeight(window.innerHeight));
    }, 300); // Adjust debounce time (in milliseconds) as needed

    window.addEventListener('resize', handleResize);
    
    dispatch(setDevicePixelRatio(window.devicePixelRatio));

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]); // Ensure effect runs only once
  
  return (
    <RouterProvider router={router} />
  )
}

export default App;