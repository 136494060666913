import React, { useState, useEffect, CSSProperties } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useInfoFetch } from '../../hooks/useInfoFetch'; 
import { Link, NavLink, useLocation } from 'react-router-dom';
import { mockContactContent } from '../../mock/data';
import { JsxElement } from 'typescript';
import { InfoBox, ArchiveLink } from '../public/ContactPage';

const ContactPage = () => {
  const mc = mockContactContent;

  const infoBoxStyles: CSSProperties = {
    textAlign: 'left',
  }

  return (
    <div className='info-outer'>
      <div className="info-row flex justify-start">
        <InfoBox name={mc.studioContact.name}  content={mc.studioContact.content}  style={infoBoxStyles}  />
        <InfoBox name={mc.agentContact.name}   content={mc.agentContact.content}   style={infoBoxStyles}  />
        <InfoBox name={mc.galleryContact.name} content={mc.galleryContact.content} style={infoBoxStyles}  />
      </div>

      <div className="info-row lg:grid" style={{gridTemplateColumns: '1fr 1fr 2fr'}}>
        <InfoBox name={mc.commercialClients.name} content={mc.commercialClients.content} style={infoBoxStyles} />
        <InfoBox name={mc.editorialClients.name}  content={mc.editorialClients.content}  style={infoBoxStyles} />
        <div>&nbsp;</div>
      </div>
      <div className="info-row" style={{}}>
        <InfoBox name=""  content={<ArchiveLink />} style={{}} />
      </div>
    </div>
  )
} 

export default ContactPage