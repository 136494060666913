import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

import { ProjectStatus } from '../types/_base'
import type { RestEndpointState, Project, ImageElement, TextBlock, PageElement } from '../types/_base'

export interface LightboxState {
  open: boolean,
  pageElements: PageElement[],
  activeElement: PageElement | null,
  nextElement: PageElement | null,
  prevElement: PageElement | null,
}

const initialState: LightboxState = {
  open: false,
  pageElements: [],
  activeElement: null,
  nextElement: null,
  prevElement: null,
};

const lightboxSlice = createSlice({
  name: 'lightbox',
  initialState,
  reducers: {
    openLightbox(state) {
      state.open = true;
    },
    closeLightbox(state) {
      state.open = false;
    },
    setActiveElement(state, action: PayloadAction<{ pageElementId: number | null }>) {
      const { pageElementId } = action.payload;
      const { pageElements } = state;
      const pageElement = pageElements.find((pageElement) => pageElement.id === pageElementId);
      if (pageElement) {
        state.activeElement = pageElement;
        const activeIndex = pageElements.indexOf(pageElement);
        if (activeIndex > 0 && activeIndex < pageElements.length - 1) {
          state.nextElement = pageElements[activeIndex + 1];
          state.prevElement = pageElements[activeIndex - 1];
        } else if (activeIndex === 0) {
          state.nextElement = pageElements[activeIndex + 1];
          state.prevElement = pageElements[pageElements.length - 1];
        } else if (activeIndex === pageElements.length - 1) {
          state.nextElement = pageElements[0];
          state.prevElement = pageElements[activeIndex - 1];
        }
      }
    },
    setPageElements(state, action: PayloadAction<{ pageElements: PageElement[] }>) {
      const { pageElements } = action.payload;
      state.pageElements = pageElements;
    },
    advanceLightbox(state) {
      const { pageElements, activeElement } = state;
      let nextIndex: number;
      if (activeElement) {
        const activeIndex = pageElements.findIndex((pageElement) => pageElement.id === activeElement.id);
        if (activeIndex < pageElements.length - 1) {
          nextIndex = activeIndex + 1;
        } else {
          nextIndex = 0;
        }
        const nextElement = pageElements[nextIndex];
        state.activeElement = nextElement;
        if (nextIndex > 0 && nextIndex < pageElements.length - 1) {
          state.nextElement = pageElements[nextIndex + 1];
          state.prevElement = pageElements[nextIndex - 1];
        } else if (nextIndex === 0) {
          state.nextElement = pageElements[nextIndex + 1];
          state.prevElement = pageElements[pageElements.length - 1];
        } else if (nextIndex === pageElements.length - 1) {
          state.nextElement = pageElements[0];
          state.prevElement = pageElements[nextIndex - 1];
        }
      }
    },
    retreatLightbox(state) {
      const { pageElements, activeElement } = state;
      let prevIndex: number;
      console.log("retreatLightbox heard!")
      if (activeElement) {
        const activeIndex = pageElements.findIndex((pageElement) => pageElement.id === activeElement.id);
        if (activeIndex === 0) {
          prevIndex = pageElements.length - 1;
        } else {
          prevIndex = activeIndex - 1;
        }
        const prevElement = pageElements[prevIndex];
        state.activeElement = prevElement;
        if (prevIndex > 0 && prevIndex < pageElements.length - 1) {
          state.nextElement = pageElements[prevIndex + 1];
          state.prevElement = pageElements[prevIndex - 1];
        } else if (prevIndex === 0) {
          state.nextElement = pageElements[prevIndex + 1];
          state.prevElement = pageElements[pageElements.length - 1];
        } else if (prevIndex === pageElements.length - 1) {
          state.nextElement = pageElements[0];
          state.prevElement = pageElements[prevIndex - 1];
        }
      } else {
        console.log("activeElement is null")
      }
    },
  },
});


export const {
  openLightbox,
  closeLightbox, 
  setPageElements, 
  setActiveElement,
  advanceLightbox,
  retreatLightbox,
} = lightboxSlice.actions;
export default lightboxSlice.reducer;