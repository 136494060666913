import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { projectApi } from '../services/projects'
import { infoApi } from '../services/info'
import projectReducer from '../features/projectSlice'
import lightboxReducer from '../features/lightboxSlice';
import infoReducer from '../features/infoSlice';
import deviceReducer from '../features/deviceSlice';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [projectApi.reducerPath]: projectApi.reducer,
    [infoApi.reducerPath]: infoApi.reducer,
    projects: projectReducer,
    lightbox: lightboxReducer,
    info: infoReducer,
    device: deviceReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(projectApi.middleware)
      .concat(infoApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
