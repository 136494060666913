import React from 'react';
import { Outlet, ScrollRestoration } from "react-router-dom";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { resetLiveElements } from '../features/projectSlice';

import { ProjectStatus } from '../types/_base';

import PublicHeader from '../partials/PublicHeader';

import useBodyClass from '../util/useBodyClass';

export default function PrivateRoute() {
  useBodyClass();
  // const dispatch = useDispatch();
  // const location = useLocation();

  return (
    <>
      <Outlet />
    </>
  );
}