import { CSSProperties, useState, useEffect } from "react";
import { useAppSelector } from '../app/hooks';

import { IMAGE_FILE_HEIGHTS, IMAGE_FILE_WIDTHS } from "../types/constants";
import { findSmallestLargerThanN } from "../util/collections";
import { set } from "lodash";

export const DEFAULT_PIXEL_FRACTION = 0.75;

export type ImageFileParts = {
  prefix: string,
  suffix: string,
  aspectRatio: number | null | undefined,
  verticalPixels?: number,
  horizontalPixels?: number,
  style?: CSSProperties | null,
  className?: string | null,
  pixelFraction?: number,
  lazyLoad?: boolean, // Optional lazy load
  onLoadCallback?: () => void, // Optional onLoad callback
}

// export const AppImage = (props: ImageFileParts) => {
export const AppImage = ({ preload = false, ...props }: ImageFileParts & { preload?: boolean }) => {
  const { prefix, suffix, verticalPixels, horizontalPixels, onLoadCallback } = props;
  const style = props.style || {};
  const pixelFraction = props.pixelFraction || DEFAULT_PIXEL_FRACTION;
  const devicePixelRatio: number = useAppSelector(state => state.device.devicePixelRatio);

  const shouldLoadLazily = props.hasOwnProperty('lazyLoad') ?  props.lazyLoad : true;

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [classNames, setClassNames] = useState<string[]>([]);
  const [cssClassName, setCssClassName] = useState<string>('');
  
  let constrainingDimension: 'vertical' | 'horizontal';
  let fileUrl:      string;
  let standardUrl:  string;
  let doubleUrl:    string;
  let tripleUrl:    string;

  // In-line styles 
  let styl = {...style}
  
  // Shape of the image
  const aspectRatio = props.aspectRatio || null;
 
  if (verticalPixels && horizontalPixels) {
    if (!aspectRatio) {
      throw new Error('AppImage: Need to provide an aspect ratio if both verticalPixels and horizontalPixels are provided');
    }
    const viewportAspectRatio = horizontalPixels / verticalPixels;
    const imageAspectRatio = aspectRatio;
    constrainingDimension = viewportAspectRatio > imageAspectRatio ? 'vertical' : 'horizontal';
  } else if (verticalPixels) {
    constrainingDimension = 'vertical';
  } else if (horizontalPixels) {
    constrainingDimension = 'horizontal';
  } else {
    constrainingDimension = 'horizontal';
  }

  const widthToUse  = ( horizontalPixels || 400 ) * pixelFraction;
  const heightToUse = ( verticalPixels   || 600 ) * pixelFraction;

  if (constrainingDimension === 'horizontal') {
    const standardWidth = findSmallestLargerThanN(IMAGE_FILE_WIDTHS,   widthToUse );
    const doubleWidth   = findSmallestLargerThanN(IMAGE_FILE_WIDTHS, ( widthToUse * 2 ));
    const tripleWidth   = findSmallestLargerThanN(IMAGE_FILE_WIDTHS, ( widthToUse * 3 ));
    
    standardUrl = `${prefix}w${standardWidth}_${suffix}`;
    doubleUrl   = `${prefix}w${doubleWidth}_${suffix}`;
    tripleUrl   = `${prefix}w${tripleWidth}_${suffix}`;
  } else {
    const standardHeight = findSmallestLargerThanN(IMAGE_FILE_HEIGHTS,   heightToUse );
    const doubleHeight   = findSmallestLargerThanN(IMAGE_FILE_HEIGHTS, ( heightToUse * 2 ));
    const tripleHeight   = findSmallestLargerThanN(IMAGE_FILE_HEIGHTS, ( heightToUse * 3 ));
    
    standardUrl = `${prefix}h${standardHeight}_${suffix}`;
    doubleUrl   = `${prefix}h${doubleHeight}_${suffix}`;
    tripleUrl   = `${prefix}h${tripleHeight}_${suffix}`;
  }
    
  fileUrl = devicePixelRatio > 2 ? tripleUrl : devicePixelRatio > 1 ? doubleUrl : standardUrl;
  
  if (aspectRatio) {
    styl = {...style, aspectRatio: aspectRatio}
  } 

  const srcSet = `${standardUrl} 1x, ${doubleUrl} 2x, ${tripleUrl} 3x`;

  const handleImageLoadCallback = () => {
    setImageLoaded(true);
    if (onLoadCallback) {
      onLoadCallback();
    }
  };

  useEffect(() => {
    setImageLoaded(false);
  }, [suffix]);
  
  useEffect(() => {
    const newClassNames: string[] = []; 
    if (props.className) {
      newClassNames.push(props.className);
    }
    if (imageLoaded) {
      newClassNames.push('srcLoaded');  
    } else {
      newClassNames.push('srcNotLoaded');
    }
    setCssClassName(newClassNames.join(' '));
  }, [props.className, imageLoaded]);

  if (preload) {
    // console.log('Preloading: ' + fileUrl);
    const image = new Image();
    image.src = fileUrl; // Use your logic to determine the appropriate URL
    // Optionally handle onLoad and onError events for the Image
    return (<></>); // Do not render anything to the DOM
  } else {
    return (
      <img
        loading={shouldLoadLazily ? 'lazy' : 'eager'}
        src={standardUrl}
        srcSet={srcSet}
        style={styl}
        className={cssClassName}
        onLoad={handleImageLoadCallback} // Attach the onLoad handler
      />
    )
  }
}

export default AppImage;