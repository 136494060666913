import React, { useState, useEffect } from 'react';

const SpecialStatusContainer = (props: { children: React.ReactNode }) => {
  return (
    <div className='fixed inset-0 w-full h-full flex justify-center items-center'>
      {props.children}
    </div>
  )

}

export const LoadingIndicator = () => {
  return (
    <SpecialStatusContainer>
      Loading...
    </SpecialStatusContainer>
  )
}

export const ErrorDisplay = (props: { errorMessage: string }) => {
  return (
    <SpecialStatusContainer>
      {props.errorMessage}
    </SpecialStatusContainer>
  )
}