import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppSelector } from '../app/hooks';

const useBodyClass = (): void => {
  const location = useLocation();
  const lightboxIsOpen: boolean = useAppSelector(state => state.lightbox.open);

  useEffect(() => {
    const matchesPrivatePath = (path: string): boolean => {
      // Regular expression to match paths like "/private/anything-here"
      const regex = /^\/private\/[^\/]+$/;
      return regex.test(path);
    };

    // Updated function to map the path to a specific class name
    const pathToClassName = (path: string): string => {
      switch (path) {
        case '/info':
        case '/contact':
        case '/contact/A':
        case '/contact/B':
        case '/contact/C':
        case '/contact/D':
        case '/contact/E':
        case '/contact/F':
          return 'dark-mode';
        // Add more cases as needed
        default:
          return '';
      }
    };

    const darkModeClassName = pathToClassName(location.pathname);
    const verticalHorizontalClassName = matchesPrivatePath(location.pathname) ? 'horizontal' : 'vertical';
    const lightboxClassName = lightboxIsOpen ? 'lightbox-open' : 'lightbox-closed';

    // Set the class on the body tag
    document.body.className = [
      darkModeClassName,
      verticalHorizontalClassName, 
      lightboxClassName
    ].join(' ').trim();

    // Clean up the class when the component is unmounted or the path changes
    return () => {
      document.body.className = '';
    };
  }, [location, lightboxIsOpen]);
};

export default useBodyClass;

// const useBodyClass = (): void => {
//   const location = useLocation();
//   const lightboxIsOpen: boolean = useAppSelector(state => state.lightbox.open);

//   useEffect(() => {
//     // Define a function to map the path to a specific class name
//     const pathToClassName = (path: string): string => {
//       switch (path) {
//         case '/info':
//           return 'dark-mode';
//         case '/contact':
//           return 'dark-mode';
//         case '/contact/A':
//           return 'dark-mode';
//         case '/contact/B':
//           return 'dark-mode';
//         case '/contact/C':
//           return 'dark-mode';
//         case '/contact/D':
//           return 'dark-mode';
//         case '/contact/E':
//           return 'dark-mode';
//         case '/contact/F':
//           return 'dark-mode';
//         // Add more cases as needed
//         default:
//           return '';
//       }
//     };

//     // Get the class name based on the current path
//     const darkModeClassName = pathToClassName(location.pathname);
//     const lightboxClassName = lightboxIsOpen ? 'lightbox-open' : 'lightbox-closed';

//     // Set the class on the body tag
//     document.body.className = [ darkModeClassName, lightboxClassName ].join(' ');

//     // Clean up the class when the component is unmounted or the path changes
//     return () => {
//       document.body.className = '';
//     };
//   }, [location, lightboxIsOpen]);
// };

// export default useBodyClass;