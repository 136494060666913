// useProjectsFetch.ts
import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useGetProjectsQuery, useGetProjectQuery } from '../services/projects';
import { ProjectStatus, Project, PageElement, ImageElement } from '../types/_base';
import { getSingleProjectById } from '../features/projectSlice';

export const useProjectsFetch = (status: ProjectStatus) => {
  // const dispatch = useAppDispatch();
  const projects: Project[] = useAppSelector(state => state.projects.projects[status]) || [];
  const images: ImageElement[] = useAppSelector(state => state.projects.images[status]) || [];

  const pageElements: Array<PageElement> = projects.reduce((acc, project) => {
    project.pageElements.forEach(pe => acc.push(pe));
    return acc; // Return the accumulator
  }, [] as PageElement[]); // Initialize the accumulator as an empty array of PageElement
  
  const { data, error, isLoading, refetch } = useGetProjectsQuery(status, {
    skip: projects.length > 0,
  });
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  useEffect(() => {
    if (projects.length === 0 && !hasFetched && !isLoading && !error) {
      refetch();
      setHasFetched(true);
    }
  }, [projects, refetch, hasFetched, isLoading, error]);

  return { projects, images, pageElements, data, error, isLoading, hasFetched };
};

export const useSingleProjectFetch = (projectId: string) => {
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  
  const project: Project | null = useAppSelector(state => getSingleProjectById(state, projectId)) || null;

  const pageElements: Array<PageElement> = project?.pageElements || [];
  
  const { data, error, isLoading, isSuccess, refetch } = useGetProjectQuery(projectId, {
    skip: hasFetched
  });

  // Update the hasFetched state for the current status when the fetch is successful
  useEffect(() => {
    if (isSuccess && !hasFetched) {
      setHasFetched(true);
    }
  }, [isSuccess, hasFetched]);

  return { project, pageElements, data, error, isLoading, hasFetched };
};