import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

import { ProjectStatus } from '../types/_base'
import type { Project, ProjectWithUI, ImageElement, SuiteElement, TextBlock, PageElement } from '../types/_base'

export interface DeviceState {
  devicePixelRatio: number,
  viewPortWidth: number,
  viewPortHeight: number,
}

const initialState: DeviceState= {
  devicePixelRatio: window.devicePixelRatio,
  viewPortWidth: window.innerWidth,
  viewPortHeight: window.innerHeight,
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setDevicePixelRatio(state, action: PayloadAction<number>) {
      state.devicePixelRatio = action.payload;
    },
    setViewPortWidth(state, action: PayloadAction<number>) {
      state.viewPortWidth = action.payload;
    },
    setViewPortHeight(state, action: PayloadAction<number>) {
      state.viewPortHeight = action.payload;
    }
  },
});

export const { setDevicePixelRatio, setViewPortHeight, setViewPortWidth } = deviceSlice.actions;
export default deviceSlice.reducer;