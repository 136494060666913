import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ProjectStatus } from '../types/_base'
import type { Project, ImageElement, TextBlock, PageElement } from '../types/_base'
import { setProjects, addProject } from '../features/projectSlice';

type ProjectsAccumulator = {
  [key in ProjectStatus]?: Project[];
};

const projectApiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/projects`;

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: fetchBaseQuery({ baseUrl: projectApiEndpoint }),
  endpoints: (builder) => ({
    getProject: builder.query<Project, string>({
      query: (id) => `${id}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const id = arg;
        try {
          const { data: project } = await queryFulfilled;
          if (project) {
            dispatch(addProject(project));
          } else {
            console.log("Didn't find project for id \"" + id + "\"");
          }
        } catch (error) {
          // Handle error
        }
      },
    }),
    getProjects: builder.query<Project[], ProjectStatus>({
      query: (status) => `?status=${status}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: projects } = await queryFulfilled;

          const groupedByStatus = projects.reduce<ProjectsAccumulator>((acc, project) => {
            const status = project.status;
            if (!acc[status]) acc[status] = [];
            acc[status]!.push(project); // Use non-null assertion here
            return acc;
          }, {});
          
          Object.keys(groupedByStatus).forEach(key => {
            const status = ProjectStatus[key as keyof typeof ProjectStatus];
            const projects = groupedByStatus[status];
            if (projects) {
              console.log("found projects for status \"" + status + "\"")
              console.log(projects)
              dispatch(setProjects({ status, projects }));
            } else {
              console.log("NO projects for \"" + status + "\"")
              console.log(projects)
            }
          });


        } catch (error) {
          // Handle error
        }
      },
    }),
  }),
});


export const { 
  // useGetQuery, 
  useGetProjectQuery, 
  useGetProjectsQuery, 
} = projectApi 