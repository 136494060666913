import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import AppImage from './AppImage';

import { PageElement, ImageElement, SuiteElement } from '../types/_base';

export type SuiteContainerShape = 'lightbox' | 'recent';

interface SuiteImageProps {
  image: ImageElement;
  containerShape: SuiteContainerShape;
}

const SuiteImage = (props: SuiteImageProps) => {
  const { image, containerShape } = props;
  const { file, aspectRatio } = image;
  const { prefix, suffix } = file;
  // const fileUrl = `${prefix}h800_${suffix}`;
 
  const device = useAppSelector(state => state.device);
  const { viewPortWidth, viewPortHeight } = device;

  const horizontalUnits = containerShape === 'lightbox' ? 48 : 40;

  const xOffsetPercent = ( 100 * (( image.xOffset || 0 ) / horizontalUnits ));
  const yOffsetPercent = ( 100 * (( image.yOffset || 0 ) / 24 ) );
  const heightPercent = ( 100 * ( image.hProportion || 12 ) / 24 );
  
  const divStyle = {
    left: xOffsetPercent + '%', 
    top:  yOffsetPercent + '%', 
    height: heightPercent + '%', 
  }

  // TODO for recent
  const canvasWidth = containerShape === 'recent' ? viewPortWidth * 0.715 : viewPortWidth * 0.8;
  const canvasHeight = containerShape === 'recent' ? viewPortHeight * 0.6 : viewPortWidth * 0.5;
  const imgHeight = canvasHeight * heightPercent / 100;
  const imgWidth = canvasWidth * heightPercent / 100 / ( aspectRatio || 1 );

  const imgStyle = {
    aspectRatio: image.aspectRatio,
    backgroundColor: '#e8e8e3',
  }

  return ( 
    <div className="positioned-suite-image suite-image" style={divStyle}>
      <AppImage 
        prefix={prefix} 
        suffix={suffix} 
        aspectRatio={aspectRatio} 
        style={imgStyle}  
        verticalPixels={imgHeight}
        horizontalPixels={imgWidth}
      />
    </div>
  )
}

interface SuiteProps {
  suite: SuiteElement;
  containerShape: SuiteContainerShape;
}

export const Suite = (props: SuiteProps) => {
  const { suite } = props;
  const images = suite.images;
 
  const className = props.containerShape === 'lightbox' ? 'forty-eight-width' : 'forty-width';

  // for debugging
  const style = {
    backgroundColor: '#d8d8d3',
  }

  return (
    <div className={`suite-display ${className}`}>
      {images.map((image, index) => {
        return <SuiteImage key={index} image={image} containerShape={props.containerShape} />
      })}
    </div>
  )
}

export default Suite 