///////////////////
// NETWORK, etc. //

export interface RestEndpointState {
  status: 'idle' | 'pending' | 'success' | 'error';
}

//////////////
// C.R.U.D. //

export interface CrudRecord {
  hasChanged?: boolean,
  crudErrors?: Array<any>,
  selected?: boolean,
  focused?: boolean,
}

///////////////////
// DOMAIN MODELS //

export interface ImageFileParts {
  prefix: string,
  suffix: string,
}

export interface PageElementBase {
  id:         number,
  type?:      string,
  rank?:      number,
  projectId?: number,
  body?:      string 
}

export interface ImageElement extends PageElementBase {
  aspectRatio?: number,
  width?:       number,
  height?:      number,
  xOffset?:     number,
  yOffset?:     number,
  hProportion?: number,
  suiteId?:     number,
  file:         ImageFileParts,
}

export interface TextBlock extends PageElementBase {
  xOffset?:     number,
  yOffset?:     number,
}

export interface SuiteElement extends PageElementBase {
  imgCount?: number,
  images: ImageElement[],
}

export type PageElement = ImageElement | TextBlock | SuiteElement;
export type VisualElement = ImageElement | SuiteElement;

export enum GridSize {
  small   = "small",
  medium  = "medium",
  large   = "large",
}

// PROJECT-RLEATED STUFF

export enum ProjectStatus {
  overview    = "overview",
  recent      = "recent",
  commercial  = "commercial",
  archive     = "archive",
  private     = "private",
  drafts      = "drafts",
  trash       = "trash",
}


export interface Project {
  id:       number,
  rank?:    number,
  title?:   string,
  status:   ProjectStatus,
  client?:  string,
  caption?: string,
  pageElements: PageElement[],
}

export interface ProjectWithUI extends Project {
  liveElement?: PageElement | null,
  nextElement?: PageElement | null,
  prevElement?: PageElement | null,
  liveImage?: ImageElement | null,
  nextImage?: ImageElement | null,
  prevImage?: ImageElement | null,
  originalLiveElement?: PageElement | null,
  originalNextImage?: ImageElement | null,
  originalPrevImage?: ImageElement | null,
}

interface ProjectSet {
  ProjectStatus: Array<Project> 
} 

export interface ContactSection {
  id?:      number,
  rank?:    number,
  name:     string,
  content:  string,
}