import React, { useState, useEffect, CSSProperties } from 'react';
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useGetProjectsQuery } from '../../services/projects'

import { useSingleProjectFetch } from '../../hooks/useProjectsFetch'; 

import { ProjectStatus, GridSize } from '../../types/_base'
import { 
  Project, PageElement, ImageElement, SuiteElement, TextBlock, ImageFileParts 
} from '../../types/_base'

import AppImage from '../../components/AppImage'; 
import { text } from 'stream/consumers';
  
const numIncrements = 24;

const PrivateGalleryImage = (props: { image: ImageElement, viewPortHeight: number, viewPortWidth: number }) => {
  const { image, viewPortHeight, viewPortWidth } = props;
  // const image = el as ImageElement;
  const { prefix, suffix } = image.file;
  const { xOffset, yOffset, hProportion, aspectRatio } = image;


  const width   = ( ( aspectRatio || 1 ) * ( hProportion || 1 ) * viewPortHeight ) / numIncrements;
  const height  = ( ( hProportion || 1 ) * viewPortHeight ) / numIncrements;
  
  const paddingLeft = ( ( xOffset || 1 ) * viewPortHeight ) / numIncrements;
  const paddingTop  = ( ( yOffset || 1 ) * viewPortHeight ) / numIncrements;

  let wrapperStyle: CSSProperties;
  
  if (viewPortWidth > 812) {
    wrapperStyle = {
      paddingLeft: `${paddingLeft}px`, 
      paddingTop: `${paddingTop}px`, 
      height: `${viewPortHeight}px`,
      width: `${paddingLeft + width}px`,
    }
  } else {
    wrapperStyle = {
      paddingLeft: 0, 
      paddingTop: 0, 
      height: 'auto',
      width: '100%',
    }
  }
  
  return (
    <div className="image-wrapper" style={wrapperStyle}>
      <AppImage prefix={prefix} suffix={suffix} aspectRatio={aspectRatio} verticalPixels={height} />
    </div>
  )
}

const PrivateGalleryTextBlock = (props: { textBlock: TextBlock, viewPortHeight: number, viewPortWidth: number }) => {
  const { textBlock, viewPortHeight, viewPortWidth } = props;
  const { xOffset, yOffset } = textBlock;

  const aspectRatio = 0.6666666666666666;
  const hProportion = 23 - ( yOffset || 1 ); 
  
  const width   = ( ( aspectRatio || 1 ) * ( hProportion || 1 ) * viewPortHeight ) / numIncrements;
  const height  = ( ( hProportion || 1 ) * viewPortHeight ) / numIncrements;
  
  const paddingLeft = ( ( xOffset || 1 ) * viewPortHeight ) / numIncrements;
  const paddingTop  = ( ( yOffset || 1 ) * viewPortHeight ) / numIncrements;

  let wrapperStyle: CSSProperties;
  let innerStyle: CSSProperties;
  
  if (viewPortWidth > 812) {
    wrapperStyle = {
      paddingLeft: `${paddingLeft}px`, 
      paddingTop: `${paddingTop}px`, 
      height: `${viewPortHeight}px`,
      width: `${paddingLeft + width}px`,
    }
    innerStyle = {
      width: "789.987px"
    }
  } else {
    wrapperStyle = {
      paddingLeft: 0, 
      paddingTop: 0, 
      height: 'auto',
      width: '100%',
    }
    innerStyle = {
      width: "100%"
    }
  }
  

  const bodyContent = textBlock.body || '';

  return (
    <div className="text-block-wrapper" data-h-proportion="22" data-y-offset="1" data-x-offset="1" style={wrapperStyle}>
      <div className="text-inner" style={innerStyle} dangerouslySetInnerHTML={{__html: bodyContent}} />
    </div>
  )
}

const PrivatePageContent = (props: { pageElements: PageElement[] }) => {
  const { pageElements } = props;

  const { viewPortHeight, viewPortWidth } = useAppSelector(state => state.device);

  return (
    <>
      {pageElements.map((el, index) => {
        if (el.type === "Image") { 
          return ( 
            <PrivateGalleryImage 
              key={index} 
              image={el as ImageElement}
              viewPortHeight={viewPortHeight}
              viewPortWidth={viewPortWidth}
            /> 
          )
        } else if (el.type === "TextBlock") {
          return ( 
            <PrivateGalleryTextBlock 
              key={index} 
              textBlock={el as TextBlock}
              viewPortHeight={viewPortHeight}
              viewPortWidth={viewPortWidth}
            /> 
          )
        } else {
          return (<>{el.type}</>)
        }
      })}
    </>
  )
}

const PrivatePage = () => {
  const { slug } = useParams();

  const { project, hasFetched, pageElements, data, error, isLoading } = useSingleProjectFetch(slug || '');

  if (isLoading) {
    return (
      <div className='private-project'>
        <h1>Loading...</h1>
      </div>
    )
  } else if (error) {
    return (
      <div className='private-project'>
        <h1>Error</h1>
      </div>
    )
  } else if (!project) {
    return (
      <div className='private-project'>
        <h1>Project not found</h1>
      </div>
    )
  } else {
    return (
      <div className='private-project'>
        <PrivatePageContent pageElements={pageElements} />
      </div>
    )
  }
}

export default PrivatePage