import React, { CSSProperties, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useInfoFetch } from '../../hooks/useInfoFetch';
import { getContactSectionByName } from '../../features/infoSlice';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { mockContactContent } from '../../mock/data';
import { JsxElement } from 'typescript';
import { ContactSection } from '../../types/_base';

interface InfoBoxProps {
  name: string;
  content: React.ReactNode;
  className?: string; // Optional additional className
  style?: CSSProperties; // Optional inline styles
}

export const InfoBox = ({ name, content, className = '', style = {} }: InfoBoxProps) => {
  const isEmpty = content === '';
  // Combine the base className with any additional className passed as a prop
  const baseClassName = isEmpty ? 'info-box hidden md:block' : 'info-box';
  const combinedClassName = `${baseClassName} ${className}`.trim();
  const bodyContent = typeof content === 'string' ? 
    <div dangerouslySetInnerHTML={{ __html: content }} /> :
    content; 

  return (
    <div className={combinedClassName} style={style}>
      {name && <h2>{name}</h2>}
      {bodyContent}
    </div>
  );
};

export const ArchiveLink = () => {
  return (
    <NavLink to={'/archive'} className='underline underline-offset-8'>
      Older works (2010 &mdash; 2020)
    </NavLink>
  )
}

interface ContactPageProps {
  studioContact: ContactSection;
  galleryContact: ContactSection;
  agentContact: ContactSection;
  commercialClients: ContactSection;
  editorialClients: ContactSection;
}

export const MobileContactContent = (props: ContactPageProps) => {
  const { studioContact, galleryContact, agentContact, commercialClients, editorialClients } = props;

  const infoBoxStyles: CSSProperties = {
    textAlign: 'left',
    padding: '0',
    marginBottom: '4rem',
  }

  const gridStyles: CSSProperties = { padding: '0 1rem' }

	return (
    <div className="info-row" style={gridStyles}>
      {studioContact.content.length  > 0 && <InfoBox name={studioContact.name}  content={studioContact.content}  style={infoBoxStyles}  />}
      {agentContact.content.length   > 0 && <InfoBox name={agentContact.name}   content={agentContact.content}   style={infoBoxStyles}  />}
      {galleryContact.content.length > 0 && <InfoBox name={galleryContact.name} content={galleryContact.content} style={infoBoxStyles}  />}
      
      {commercialClients.content.length > 0 && <InfoBox name={commercialClients.name} content={commercialClients.content} style={infoBoxStyles} />}
      {editorialClients.content.length  > 0 && <InfoBox name={editorialClients.name}  content={editorialClients.content}  style={infoBoxStyles} />}

      <InfoBox name=""  content={<ArchiveLink />} style={infoBoxStyles} />
    </div>
	)
}

export const LargeContactContent = (props: ContactPageProps) => {
  const { studioContact, galleryContact, agentContact, commercialClients, editorialClients } = props;

  const gridStyles: CSSProperties = {
    columnGap: '2.380952381vw',
    padding: '0 2.380952381vw',
  }

	return (
    <div className="info-row" style={gridStyles}>
      {studioContact.content.length  > 0 ? <InfoBox name={studioContact.name}  content={studioContact.content} /> : <InfoBox name='' content='' />}
      {commercialClients.content.length  > 0 ? <InfoBox name={commercialClients.name} content={commercialClients.content} /> : <InfoBox name='' content='' />}
      <div>&nbsp;</div>

      {agentContact.content.length  > 0 ? <InfoBox name={agentContact.name}   content={agentContact.content} /> : <InfoBox name='' content='' />}
      {editorialClients.content.length  > 0 ? <InfoBox name={editorialClients.name}  content={editorialClients.content} /> : <InfoBox name='' content='' />}
      <div>&nbsp;</div>

      {galleryContact.content.length  > 0 ? <InfoBox name={galleryContact.name} content={galleryContact.content} /> : <InfoBox name='' content='' />}
      <InfoBox name=""  content={<ArchiveLink />} />
      <div>&nbsp;</div>
    </div>
	)
}

const ContactPage = () => {
  const { contactSections, data, error, isLoading } = useInfoFetch();
  
  // const mc = mockContactContent;
  // const { studioContact, galleryContact, commercialClients, editorialClients } = mc;
  // const agentContact = { name: '', content: '' };

  const studioContact  = useAppSelector(state => getContactSectionByName(state, 'studio'))  || { name: '', content: '' };
  const galleryContact = useAppSelector(state => getContactSectionByName(state, 'gallery')) || { name: '', content: '' };
  const agentContact   = useAppSelector(state => getContactSectionByName(state, 'commissions'))   || { name: '', content: '' };
 
  const commercialClients = useAppSelector(state => getContactSectionByName(state, 'commercial')) || { name: '', content: '' };
  const editorialClients  = useAppSelector(state => getContactSectionByName(state, 'editorial'))  || { name: '', content: '' };

	return (
    <>
      <div className='info-outer small-version'>
        <MobileContactContent
          studioContact={studioContact}
          galleryContact={galleryContact}
          agentContact={agentContact}
          commercialClients={commercialClients}
          editorialClients={editorialClients} 
        />
      </div>
      
      <div className='info-outer large-version'>
        <LargeContactContent 
          studioContact={studioContact}
          galleryContact={galleryContact}
          agentContact={agentContact}
          commercialClients={commercialClients}
          editorialClients={editorialClients} 
        />
      </div>
    </>
	)
}

export default ContactPage