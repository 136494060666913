import React, { useState, useEffect, CSSProperties } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useInfoFetch } from '../../hooks/useInfoFetch'; 
import { getContactSectionByName } from '../../features/infoSlice';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { mockContactContent } from '../../mock/data';
import { JsxElement } from 'typescript';
import { InfoBox, ArchiveLink, MobileContactContent, LargeContactContent } from '../public/ContactPage';

const ContactPage = () => {
  const { contactSections, data, error, isLoading } = useInfoFetch();

  const studioContact  = useAppSelector(state => getContactSectionByName(state, 'studio'))  || { name: '', content: '' };
  const galleryContact = useAppSelector(state => getContactSectionByName(state, 'gallery')) || { name: '', content: '' };
  const agentContact   = useAppSelector(state => getContactSectionByName(state, 'agent'))   || { name: '', content: '' };
 
  const commercialClients = useAppSelector(state => getContactSectionByName(state, 'commercial')) || { name: '', content: '' };
  const editorialClients  = useAppSelector(state => getContactSectionByName(state, 'editorial'))  || { name: '', content: '' };

	return (
    <>
      <div className='info-outer small-version'>
        <MobileContactContent
          studioContact={studioContact}
          galleryContact={galleryContact}
          agentContact={agentContact}
          commercialClients={commercialClients}
          editorialClients={editorialClients} 
        />
      </div>
      
      <div className='info-outer large-version'>
        <LargeContactContent 
          studioContact={studioContact}
          galleryContact={galleryContact}
          agentContact={agentContact}
          commercialClients={commercialClients}
          editorialClients={editorialClients} 
        />
      </div>
    </>
	)
}

export default ContactPage