import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

import type { ContactSection } from '../types/_base'

export interface InfoState {
  contactSections: ContactSection[];
}

const initialState: InfoState = {
  contactSections: []
};

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setInfo(state, action: PayloadAction<{ contactSections: ContactSection[] }>) {
      console.log("#setInfo heard!")
      const { contactSections } = action.payload;
      state.contactSections = contactSections;
    },
  },
});

export const getContactSectionByName = (state: RootState, name: string) => {
  return state.info.contactSections.find(cs => cs.name === name);
}

export const { setInfo } = infoSlice.actions;
export default infoSlice.reducer;