import React from 'react';
import { Outlet, ScrollRestoration } from "react-router-dom";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { resetLiveElements } from '../features/projectSlice';

import { ProjectStatus } from '../types/_base';

import PublicHeader from '../partials/PublicHeader';

import useBodyClass from '../util/useBodyClass';

export default function PublicRoute() {
  useBodyClass();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleLeavePage = (currentPath: string) => {
      if (currentPath !== '/recent') {
        dispatch(resetLiveElements({ status: ProjectStatus.recent }));
      }
    };

    handleLeavePage(location.pathname);
  }, [location.pathname, dispatch, resetLiveElements]);

  return (
    <>
      <PublicHeader />
      <div id="main" className=''>
        <Outlet />
      </div>
      <ScrollRestoration />
    </>
  );
}