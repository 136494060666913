
export function findSmallestLargerThanN(numbers: number[], n: number): number {
  const result = numbers.reduce<number | null>((acc, curr) => {
    if (curr > n && (acc === null || curr < acc)) {
      return curr;
    }
    return acc;
  }, null);

  return result === null ? Math.max(...numbers) : result;
}

// export function findSmallestLargerThanN(numbers: number[], n: number): number {
//   return numbers.reduce((acc, curr) => {
//     if (curr > n && (acc === null || curr < acc)) {
//       return curr;
//     }
//     return acc;
//   }, null) ?? Math.max(...numbers);
// }