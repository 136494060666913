import React, { useState, useEffect, CSSProperties } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useInfoFetch } from '../../hooks/useInfoFetch'; 
import { Link, NavLink, useLocation } from 'react-router-dom';
import { mockContactContent } from '../../mock/data';
import { JsxElement } from 'typescript';
import { InfoBox, ArchiveLink } from '../public/ContactPage';

const ContactPage = () => {
  const mc = mockContactContent;

  const infoBoxStyles: CSSProperties = {
    textAlign: 'left',
    padding: '0',
    margin: '0 0 4.7619047619vw 0',
  }

  const gridStyles: CSSProperties = {
    gridTemplateColumns: '2fr 3fr 9fr',
    columnGap: '2.380952381vw',
    padding: '0 2.380952381vw',
  }


  return (
    <div className='info-outer'>
      <div className="info-row md:grid" style={gridStyles}>
        <InfoBox name={mc.studioContact.name}  content={mc.studioContact.content}  style={infoBoxStyles}  />
        <InfoBox name={mc.commercialClients.name} content={mc.commercialClientsShort.content} style={infoBoxStyles} />
        <div>&nbsp;</div>

        <InfoBox name={mc.agentContact.name}   content={mc.agentContact.content}   style={infoBoxStyles}  />
        <InfoBox name={mc.editorialClients.name}  content={mc.editorialClientsShort.content}  style={infoBoxStyles} />
        <div>&nbsp;</div>

        <InfoBox name={mc.galleryContact.name} content={mc.galleryContact.content} style={infoBoxStyles}  />
        <InfoBox name=""  content={<ArchiveLink />} style={infoBoxStyles} />
        <div>&nbsp;</div>
      </div>
    </div>
  )
} 

export default ContactPage