import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setPageElements, closeLightbox, advanceLightbox, retreatLightbox } from '../features/lightboxSlice';


const StandardMobileHeader = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className='small-nav'>
      <div className='top-bar flex justify-between p-4'>
        <div className=''>
          <NavLink to={'/'} className=''>
            Mark Mahaney
          </NavLink>
        </div>

        <div className=''>
          <span className='cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? 'X' : 'Menu'}
          </span>
        </div>
      </div>

      <div className={`submenu p-4 ${isOpen ? 'open' : ''}`}>
        <div className='link-wrapper'>
          <NavLink to={'/overview'} className=''>
            Overview
          </NavLink>
        </div>
        <div className='link-wrapper'>
          <NavLink to={'/recent'} className=''>
            Recent Work
          </NavLink>
        </div>
        <div className='link-wrapper'>
          <NavLink to={'/contact'} className=''>
            Contact
          </NavLink>
        </div>
      </div>
    </div>
  )
}

const MinimalMobileHeader = () => {
  const dispatch = useAppDispatch();
  const lightboxIsOpen: boolean = useAppSelector(state => state.lightbox.open);

  const handleNavLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    dispatch(closeLightbox());
  }

  return (
    <div className={`small-nav ${lightboxIsOpen ? 'lightbox-open' : 'lightbox-closed'}`}>
        <nav className='uppercase top-bar flex justify-between p-4'>
          <div className='lightbox-hide'>
            <NavLink to={'/'} className='' onClick={handleNavLinkClick}>
              Mark Mahaney
            </NavLink>
          </div>
          <div className='link-wrapper lightbox-show'>
            <NavLink to={'/'} className='' onClick={handleNavLinkClick}>
              Thumbnails
            </NavLink>
          </div>
          <div className='link-wrapper'>
            <NavLink to={'/contact'} className='' onClick={handleNavLinkClick}>
              Contact
            </NavLink>
          </div>
        </nav>
    </div>
  )
}



export const LargeScreenHeader = () => {
  const dispatch = useAppDispatch();

  const handleNavLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    dispatch(closeLightbox());
  }

	return (
    <div className="large-nav nav-outer-wrapper py-10 px-8 fixed top-0 left-0 right-0 z-30">
      <nav className='uppercase flex justify-between'>
        <div className=''>
          <NavLink to={'/'} className='' onClick={handleNavLinkClick}>
            Mark Mahaney
          </NavLink>
        </div>
        <div className='link-wrapper'>
          <NavLink to={'/overview'} className='' onClick={handleNavLinkClick}>
            Overview
          </NavLink>
        </div>
        <div className='link-wrapper'>
          <NavLink to={'/recent'} className='' onClick={handleNavLinkClick}>
            Recent Work
          </NavLink>
        </div>
        <div className='link-wrapper'>
          <NavLink to={'/contact'} className='' onClick={handleNavLinkClick}>
            Contact
          </NavLink>
        </div>
      </nav>
    </div>
  )
}

export const LargeScreenHeaderGridVersion = () => {
  const dispatch = useAppDispatch();

  const styl = {
    padding: '0 2.380952381vw 4.7619047619vw'
  }

  const handleNavLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    dispatch(closeLightbox());
  }

	return (
    <div className="large-nav nav-outer-wrapper py-10 px-8 fixed top-0 left-0 right-0 z-30">
      <nav className='uppercase grid grid-cols-4'>
        <div className='' style={styl}>
          <NavLink to={'/'} className='' onClick={handleNavLinkClick}>
            Mark Mahaney
          </NavLink>
        </div>
        <div className='link-wrapper' style={styl}>
          <NavLink to={'/overview'} className='' onClick={handleNavLinkClick}>
            Overview
          </NavLink>
        </div>
        <div className='link-wrapper' style={styl}>
          <NavLink to={'/recent'} className='' onClick={handleNavLinkClick}>
            Recent Work
          </NavLink>
        </div>
        {/* <div className='link-wrapper text-right'> */}
        <div className='link-wrapper' style={styl}>
          <NavLink to={'/contact'} className='' onClick={handleNavLinkClick}>
            Contact
          </NavLink>
        </div>
      </nav>
    </div>
  )
}

export const BothLargeAndSmallHeaders = () => {
  return (
    <>
      <LargeScreenHeader />
      <MinimalMobileHeader />
    </>
  )
}

export default BothLargeAndSmallHeaders 