import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { setPageElements, closeLightbox, advanceLightbox, retreatLightbox } from '../features/lightboxSlice';

import { PageElement, ImageElement, SuiteElement } from '../types/_base';

import Suite from './Suite';
import AppImage from './AppImage';
import { set } from 'lodash';

export const LittleX = () => {
  const dispatch = useAppDispatch();
  const svgDataUrl = 'data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 20.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 106 106" style="enable-background:new 0 0 106 106;" xml:space="preserve"><style type="text/css">.st0{fill:%23333333;}</style><g><rect x="0.4" y="50.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 52.8291 -22.3658)" class="st0" width="106" height="4.8"/><rect x="0" y="50.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -21.9533 53)" class="st0" width="106" height="4.8"/></g></svg>';

  const clickHandler: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.preventDefault();
    dispatch(closeLightbox());
  }

  return (
    <img className="lightbox-x srcLoaded" src={svgDataUrl} alt="X" onClick={clickHandler} />
  )
}

export const ViewThumbsButton = () => {
  const dispatch = useAppDispatch();

  const clickHandler: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    dispatch(closeLightbox());
  }

  return (
    <a className='clickable' onClick={clickHandler}>
      View Thumbnails
    </a>
  )
}

const AdvanceOrRetreatButton = (props: { direction: 'next' | 'prev' }) => {
  const { direction } = props;
  const dispatch = useAppDispatch();
  const rotate = direction === 'prev' ? 'rotate(-180deg)' : 'rotate(0deg)';
  const style: React.CSSProperties = {
    writingMode: "vertical-rl",
    transform: rotate,
    zIndex: 30,
  }
  const className = direction === 'prev' ? 'absolute left-7 clickable' : 'absolute right-7 clickable';
  const buttonText = direction === 'prev' ? 'Prev' : 'Next';

  const clickHandler: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (direction === 'prev') {
      dispatch(retreatLightbox());
    } else {
      dispatch(advanceLightbox());
    }
  }

  return (
    <a className={className} style={style} onClick={clickHandler}>
      {buttonText}
    </a>
  )
}


export type LightboxImageProps = {
  image: ImageElement, 
  preload?: boolean
  onLoadCallback?: () => void, // Optional onLoad callback
}

// export const AppImage = ({ preload = false, ...props }: ImageFileParts & { preload?: boolean }) => {
const LightboxImage = ({preload = false, ...props }: LightboxImageProps) => {
  const { image } = props;
  const { prefix, suffix } = image.file;

  const viewPortWidth  = useAppSelector(state => state.device.viewPortWidth);
  const viewPortHeight = useAppSelector(state => state.device.viewPortHeight);

  const verticalPixels   = viewPortHeight * 0.6667;
  const horizontalPixels = viewPortWidth  * 0.6667;

  const imgStyle = {
    maxHeight: "66.67vh",
    maxWidth: "66.67vw",
  }

  return (
    <div className="lightbox-image lightbox-element live">
      <div className="element-inner-wrapper image">
        <AppImage 
          prefix={prefix} 
          suffix={suffix} 
          aspectRatio={image.aspectRatio} 
          verticalPixels={verticalPixels} 
          horizontalPixels={horizontalPixels} 
          style={imgStyle}
          preload={preload}
          onLoadCallback={props.onLoadCallback} 
        />
      </div>
    </div>
  )
}

export const OpenLightbox = () => {
  const dispatch = useAppDispatch();
  const activeElement: PageElement | null = useAppSelector(state => state.lightbox.activeElement);
  let lightboxContent: JSX.Element | null = null;
  let captionContent: string | null = null;

  const [ mainImageLoaded, setMainImageLoaded ] = useState(false);
  const setMainImageLoadedTrue = () => { 
    // console.log('hello from setMainImageLoadedTrue')
    setMainImageLoaded(true);
  }
  

  if (activeElement) {
    if (activeElement.type === 'Image') {
      lightboxContent = <LightboxImage image={activeElement as ImageElement} onLoadCallback={setMainImageLoadedTrue} />
      captionContent = activeElement.body || null;
    } else if (activeElement.type === 'Suite') {
      lightboxContent = <Suite suite={activeElement as SuiteElement} containerShape='lightbox' /> 
      captionContent = activeElement.body || null;
    }
  }
  
  useEffect(() => {
    if (mainImageLoaded === true) {
      // console.log('mainImageLoaded is true; setting it to false')
      setMainImageLoaded(false);
    }
  }, [lightboxContent]);
  
  
  ///////////////////////////////////////
  // Pre-load next and previous images //
  const nextElement: PageElement | null = useAppSelector(state => state.lightbox.nextElement);
  const prevElement: PageElement | null = useAppSelector(state => state.lightbox.prevElement);
  let nextComponent: JSX.Element | null = null;
  let prevComponent: JSX.Element | null = null;

  if (nextElement && nextElement.type === 'Image') {
    // console.log('nextElement IS an Image')
    const ne = nextElement as ImageElement;
    nextComponent = <LightboxImage image={nextElement as ImageElement} preload={true} />
  } else {
    // console.log('nextElement.type: ' + nextElement?.type)
  }
  if (prevElement && prevElement.type === 'Image') {
    // console.log('prevElement IS an Image')
    const pe = prevElement as ImageElement;
    prevComponent = <LightboxImage image={prevElement as ImageElement} preload={true} />
  } else {
    // console.log('prevElement.type: ' + prevElement?.type)
  }

  const advanceClickHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    dispatch(advanceLightbox());
  }
  const retreatClickHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    dispatch(retreatLightbox());
  }

  useEffect(() => {
    const handleArrowClick = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        // console.log("ArrowLeft")
        dispatch(retreatLightbox());
      } else if (event.key === 'ArrowRight') {
        // console.log("ArrowRight")
        dispatch(advanceLightbox());
      } else if (event.key === 'Escape') {
        // console.log("Escape")
        dispatch(closeLightbox());
      }
    };

    window.addEventListener('keydown', handleArrowClick);

    return () => {
      window.removeEventListener('keydown', handleArrowClick);
    };
  }, [dispatch]); // Include `dispatch` in the dependency array

  // TOUCH SWIPE HANDLING
  const [touchStart, setTouchStart] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  let debounceTimer: ReturnType<typeof setTimeout> | undefined; // Correct type for timer in browser environment

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0];
    setTouchStart({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (debounceTimer !== undefined) clearTimeout(debounceTimer); // Clear existing timer

    debounceTimer = setTimeout(() => {
      // Clear the timer ID after execution
      debounceTimer = undefined;

      const touch = e.changedTouches[0];
      const touchEnd = { x: touch.clientX, y: touch.clientY };
      const diffX = touchEnd.x - touchStart.x;

      if (Math.abs(diffX) > 50) {
        if (diffX > 0) {
          dispatch(retreatLightbox());
        } else {
          dispatch(advanceLightbox());
        }
      }
    }, 200); // 200ms debounce period
  };

  useEffect(() => {
    return () => {
      if (debounceTimer !== undefined) clearTimeout(debounceTimer); // Cleanup timer on component unmount
    };
  }, []);

  // console.log('lightboxContent: ' + lightboxContent)
  // console.log('nextComponent: ' + nextComponent)
  return (
    <div 
      className="lightbox shown bg-beige-95" 
      onTouchStart={handleTouchStart} 
      onTouchMove={handleTouchEnd}
    >
      <AdvanceOrRetreatButton direction='prev' />
      <div className="lightbox-inner-wrapper">
        {lightboxContent}
      </div>
      <div className="h-screen w-screen fixed flex click-catcher">
        <div className="h-screen w-1/3 left-side" onClick={retreatClickHandler}></div> 
        <div className="h-screen w-2/3 right-side" onClick={advanceClickHandler}></div> 
      </div>
      <AdvanceOrRetreatButton direction='next' />
      
      <div className='absolute bottom-0 left-0 right-0 flex py-10 px-8 justify-center md:justify-between'>
        <div>{captionContent}</div>

        <div className='hidden md:block'>
          <ViewThumbsButton />
        </div>
      </div>
      {mainImageLoaded && nextComponent }
      {mainImageLoaded && prevComponent }
    </div>
  )
}

export const Lightbox = (props: { pageElements: Array<PageElement> }) => {
  const dispatch = useAppDispatch();
  const { pageElements } = props;
  const isOpen: boolean = useAppSelector(state => state.lightbox.open);
  
  useEffect(() => {
    dispatch(setPageElements({ pageElements: pageElements }));
  }, [dispatch, pageElements]);

  if (isOpen) {
    return (<OpenLightbox />)
  } else {
    return (<></>)
  }
}

export default Lightbox 