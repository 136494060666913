// useProjectsFetch.ts
import { useState, useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useGetProjectsQuery } from '../services/projects';
import { ProjectStatus, Project, PageElement, ImageElement } from '../types/_base';

export const useProjectsFetchMulti = (statuses: ProjectStatus[]) => {
  const [currentStatusIndex, setCurrentStatusIndex] = useState(0);
  const [hasFetchedStatuses, setHasFetchedStatuses] = useState<boolean[]>(Array(statuses.length).fill(false));

  // Assuming your state shape and logic to select projects and images remain the same,
  // but adjusted to work with the current status.
  const currentStatus = statuses[currentStatusIndex];
  const projects: Project[] = useAppSelector(state => state.projects.projects[currentStatus]) || [];
  const images: ImageElement[] = useAppSelector(state => state.projects.images[currentStatus]) || [];

  const pageElements: Array<PageElement> = projects.reduce((acc, project) => {
    project.pageElements.forEach(pe => acc.push(pe));
    return acc; // Return the accumulator
  }, [] as PageElement[]); // Initialize the accumulator as an empty array of PageElement

  const { data, error, isLoading, isSuccess, refetch } = useGetProjectsQuery(currentStatus, {
    skip: hasFetchedStatuses[currentStatusIndex] || projects.length > 0,
  });

  // Update the hasFetched state for the current status when the fetch is successful
  useEffect(() => {
    if (isSuccess && !hasFetchedStatuses[currentStatusIndex]) {
      setHasFetchedStatuses(prev => prev.map((fetched, index) => index === currentStatusIndex ? true : fetched));
    }
  }, [isSuccess, currentStatusIndex, hasFetchedStatuses]);

  // Function to advance to the next status
  const fetchNextStatus = useCallback(() => {
    const nextIndex = currentStatusIndex + 1;
    if (nextIndex < statuses.length) {
      setCurrentStatusIndex(nextIndex);
    }
  }, [currentStatusIndex, statuses.length]);

  // Trigger the next fetch upon completion of the current one
  useEffect(() => {
    if (hasFetchedStatuses[currentStatusIndex] && currentStatusIndex < statuses.length - 1) {
      fetchNextStatus();
    }
  }, [hasFetchedStatuses, currentStatusIndex, fetchNextStatus, statuses.length]);

  return { projects, images, pageElements, data, error, isLoading, hasFetched: hasFetchedStatuses[currentStatusIndex] };
};
