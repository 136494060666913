import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

import { ProjectStatus } from '../types/_base'
import type { Project, ProjectWithUI, ImageElement, SuiteElement, TextBlock, PageElement, VisualElement } from '../types/_base'

export interface ProjectState {
  projects: {
    [key in ProjectStatus]?: ProjectWithUI[];
  };
  images: {
    [key in ProjectStatus]?: ImageElement[];
  };
}

const initialState: ProjectState = {
  projects: {},
  images: {}
};

const findPrevAndNextImages = (pageElements: PageElement[], liveElIndex: number): { prevImage: ImageElement | undefined, nextImage: ImageElement | undefined } => {
  let prevImage: ImageElement | undefined;
  let nextImage: ImageElement | undefined;
  
  if (liveElIndex !== -1) {
    let searchIndex, foundPrev = false, foundNext = false;
    const elementCount = pageElements.length;
    for (let i = 1; i < elementCount; i++) { // Start searching from 1 position away
      // Search for previous image, if not found yet
      if (!foundPrev) {
        searchIndex = (liveElIndex - i + elementCount) % elementCount;
        if (pageElements[searchIndex].type === 'Image') {
          prevImage = getImageElement(pageElements, searchIndex);
          foundPrev = true; // Stop searching for previous
        } else if (pageElements[searchIndex].type === 'Suite') {
          const suite = pageElements[searchIndex] as SuiteElement;
          if (suite.images?.length > 0) {
            prevImage = suite.images[suite.images.length - 1] as ImageElement;
            foundPrev = true; // Stop searching for previous
          }
        }
      }
  
      // Search for next image, if not found yet
      if (!foundNext) {
        searchIndex = (liveElIndex + i) % elementCount;
        if (pageElements[searchIndex].type === 'Image') {
          nextImage = getImageElement(pageElements, searchIndex);
          foundNext = true; // Stop searching for next
        } else if (pageElements[searchIndex].type === 'Suite') {
          const suite = pageElements[searchIndex] as SuiteElement;
          if (suite.images?.length > 0) {
            nextImage = suite.images[0] as ImageElement;
            foundNext = true; // Stop searching for next
          }
        }
      }
  
      // Stop loop if both previous and next images are found
      if (foundPrev && foundNext) {
        break;
      }
    }
  }

  return { prevImage, nextImage };
}
// const findPrevAndNextImages = (pageElements: PageElement[], liveImageIndex: number): { prevImage: ImageElement | undefined, nextImage: ImageElement | undefined } => {
//   let prevImage: ImageElement | undefined;
//   let nextImage: ImageElement | undefined;
  
//   if (liveImageIndex !== -1) {
//     let searchIndex, foundPrev = false, foundNext = false;
//     const elementCount = pageElements.length;
//     for (let i = 1; i < elementCount; i++) { // Start searching from 1 position away
//       // Search for previous image, if not found yet
//       if (!foundPrev) {
//         searchIndex = (liveImageIndex - i + elementCount) % elementCount;
//         if (pageElements[searchIndex].type === 'Image') {
//           prevImage = getImageElement(pageElements, searchIndex);
//           foundPrev = true; // Stop searching for previous
//         }
//       }
  
//       // Search for next image, if not found yet
//       if (!foundNext) {
//         searchIndex = (liveImageIndex + i) % elementCount;
//         if (pageElements[searchIndex].type === 'Image') {
//           nextImage = getImageElement(pageElements, searchIndex);
//           foundNext = true; // Stop searching for next
//         }
//       }
  
//       // Stop loop if both previous and next images are found
//       if (foundPrev && foundNext) {
//         break;
//       }
//     }
//   }

//   return { prevImage, nextImage };
// }

const decorateWithUI = (project: Project): { projectWithUI: ProjectWithUI, images: Array<ImageElement> } => {
  let images: Array<ImageElement> = [];
  const { pageElements } = project;
  // NOTE: We want both:
  // - the first single image (for thumbnails); and 
  // - the first non-text element (for the live element), which can be an image or a suite

  const liveImageIndex = pageElements.findIndex((element): element is ImageElement  => element.type === 'Image');
  const liveElIndex    = pageElements.findIndex((element): element is VisualElement => element.type === 'Image' || element.type === 'Suite');

  const liveImage   = liveImageIndex !== -1 ? getImageElement(pageElements, liveImageIndex) : undefined;
  const liveElement = liveElIndex    !== -1 ? getVisualElement(pageElements, liveElIndex)   : undefined;
  const originalLiveElement = liveElement;

  // const { prevImage, nextImage } = findPrevAndNextImages(pageElements, liveImageIndex);
  const { prevImage, nextImage } = findPrevAndNextImages(pageElements, liveElIndex);

  const originalNextImage = nextImage;
  const originalPrevImage = prevImage;

  // Get all images
  pageElements.forEach((element) => {
    if (element.type === 'Image') {
      images.push(element as ImageElement);
    } else if (element.type === 'Suite') {
      const suite = element as SuiteElement;
      if (suite.images?.length > 0) {
        suite.images.forEach((image) => {
          images.push(image as ImageElement);
        });
      }
    }
  });

  const projectWithUI: ProjectWithUI = {
    ...project,
    liveElement,
    liveImage,
    nextImage,
    prevImage,
    originalLiveElement,
    originalNextImage,
    originalPrevImage,
  }

  return { projectWithUI, images };
  // return projectWithUI;
}


// Function to safely get ImageElement or undefined
const getImageElement = (pageElements: PageElement[], index: number): ImageElement | undefined => {
  const element = pageElements[index];
  if (element.type === 'Image') {
    return element as ImageElement;
  } else if (element.type === 'Suite') {
    if ((element as SuiteElement).images?.length > 0) {
      return (element as SuiteElement).images[0] as ImageElement;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  } 
};

// Function to safely get ImageElement or undefined
const getVisualElement = (pageElements: PageElement[], index: number): VisualElement | undefined => {
  const element = pageElements[index];
  if (element.type === 'Image') {
    return element as ImageElement;
  } else if (element.type === 'Suite') {
    return element as SuiteElement;
  } else {
    return undefined;
  } 
};

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    addProject(state, action: PayloadAction<Project>) {
      const project = action.payload;
      const { status } = project;
      if (!state.projects[status]) {
        state.projects[status] = [];
      }
      const { projectWithUI, images } = decorateWithUI(project); 
      state.projects[status]!.push(projectWithUI);
    },
    setProjects(state, action: PayloadAction<{ status: ProjectStatus, projects: Project[] }>) {
      // console.log("#setProjects heard!")
      const { status, projects } = action.payload;

      let images: Array<ImageElement> = [];
      let projectsWithUI: Array<ProjectWithUI> = [];
      projects.forEach((project) => {
        const { projectWithUI, images: projectImages } = decorateWithUI(project);
        projectsWithUI.push(projectWithUI);
        images.push(...projectImages);
      });

      state.projects[status] = projectsWithUI;
      state.images[status] = images;
    },
  
    advanceProjectElement(state, action: PayloadAction<{ projectId: number, status: ProjectStatus }>) {
      const { projectId, status } = action.payload;

      // Ensure the projects array for this status exists
      const projects = state.projects[status];
      if (!projects) {
        // If there are no projects under this status, exit early
        // Optionally, you might want to initialize the array here, depending on your logic
        return;
      }

      const projectIndex = projects.findIndex(p => p.id === projectId);
      if (projectIndex === -1) {
        // Project not found
        return;
      }

      const project = projects[projectIndex];
      const { pageElements } = project;
      
      // Find current live image index
      const currentLiveElIndex = pageElements.findIndex(
        (element) => project.liveElement && element.id === project.liveElement.id
      );

      // Calculate new indices
      const newLiveImageIndex = (currentLiveElIndex + 1) % pageElements.length;
      const newNextElementIndex = (newLiveImageIndex + 1) % pageElements.length;
      const newPrevElementIndex = (newLiveImageIndex - 1 + pageElements.length) % pageElements.length;
      
      const liveElement = pageElements[newLiveImageIndex];
      const prevElement = pageElements[newPrevElementIndex];
      const nextElement = pageElements[newNextElementIndex];
      
      // const prevImage = newPrevElementIndex !== -1 ? getImageElement(pageElements, newPrevElementIndex) : undefined;
      // const nextImage = newNextElementIndex !== -1 ? getImageElement(pageElements, newNextElementIndex) : undefined;
      const { prevImage, nextImage } = findPrevAndNextImages(pageElements, newLiveImageIndex);

      if (state.projects[status]) {
        state.projects[status]![projectIndex] = {
          ...project,
          liveElement,
          prevElement,
          nextElement,
          prevImage,
          nextImage
        };
      }
    },
    
    retreatProjectElement(state, action: PayloadAction<{ projectId: number, status: ProjectStatus }>) {
      const { projectId, status } = action.payload;

      // Ensure the projects array for this status exists
      const projects = state.projects[status];
      if (!projects) {
        // If there are no projects under this status, exit early
        // Optionally, you might want to initialize the array here, depending on your logic
        return;
      }

      const projectIndex = projects.findIndex(p => p.id === projectId);
      if (projectIndex === -1) {
        // Project not found
        return;
      }

      const project = projects[projectIndex];
      const { pageElements } = project;
      
      // Find current live image index
      const currentLiveElIndex = pageElements.findIndex(
        (element) => project.liveElement && element.id === project.liveElement.id
      );

      // Calculate new indices
      const newLiveImageIndex   = (currentLiveElIndex - 1 + pageElements.length) % pageElements.length;
      const newNextElementIndex = (newLiveImageIndex + 1) % pageElements.length;
      const newPrevElementIndex = (newLiveImageIndex - 1 + pageElements.length) % pageElements.length;
      
      const liveElement = pageElements[newLiveImageIndex];
      const prevElement = pageElements[(newLiveImageIndex - 1 + pageElements.length) % pageElements.length];
      const nextElement = pageElements[(newLiveImageIndex + 1) % pageElements.length];
      
      const { prevImage, nextImage } = findPrevAndNextImages(pageElements, newLiveImageIndex);
      // const prevImage = newPrevElementIndex !== -1 ? getImageElement(pageElements, newPrevElementIndex) : undefined;
      // const nextImage = newNextElementIndex !== -1 ? getImageElement(pageElements, newNextElementIndex) : undefined;

      if (state.projects[status]) {
        state.projects[status]![projectIndex] = {
          ...project,
          liveElement,
          prevElement,
          nextElement,
          prevImage,
          nextImage
        };
      }
    },

    // advanceProjectImage(state, action: PayloadAction<{ projectId: number, status: ProjectStatus }>) {
    //   const { projectId, status } = action.payload;

    //   // Ensure the projects array for this status exists
    //   const projects = state.projects[status];
    //   if (!projects) {
    //     // If there are no projects under this status, exit early
    //     // Optionally, you might want to initialize the array here, depending on your logic
    //     return;
    //   }

    //   const projectIndex = projects.findIndex(p => p.id === projectId);
    //   if (projectIndex === -1) {
    //     // Project not found
    //     return;
    //   }

    //   const project = projects[projectIndex];
    //   const { pageElements } = project;
      
    //   // Find current live image index
    //   const currentLiveImageIndex = pageElements.findIndex(
    //     (element) => element.type === 'Image' && project.liveImage && element.id === project.liveImage.id
    //   );

    //   // Calculate new indices
    //   const newLiveImageIndex = (currentLiveImageIndex + 1) % pageElements.length;
    //   const liveImage = getImageElement(pageElements, newLiveImageIndex);
    //   const prevImage = getImageElement(pageElements, (newLiveImageIndex - 1 + pageElements.length) % pageElements.length);
    //   const nextImage = getImageElement(pageElements, (newLiveImageIndex + 1) % pageElements.length);

    //   if (state.projects[status]) {
    //     state.projects[status]![projectIndex] = {
    //       ...project,
    //       liveImage,
    //       prevImage,
    //       nextImage,
    //     };
    //   }
    // },

    // retreatProjectImage(state, action: PayloadAction<{ projectId: number, status: ProjectStatus }>) {
    //   const { projectId, status } = action.payload;

    //   const projects = state.projects[status];
    //   if (!projects) {
    //     return;
    //   }

    //   const projectIndex = projects.findIndex(p => p.id === projectId);
    //   if (projectIndex === -1) {
    //     return;
    //   }

    //   const project = projects[projectIndex];
    //   const { pageElements } = project;
      
    //   const currentLiveImageIndex = pageElements.findIndex(
    //     (element) => element.type === 'Image' && project.liveImage && element.id === project.liveImage.id
    //   );

    //   // const getImageElement = (index: number): ImageElement | undefined => {
    //   //   const element = pageElements[index];
    //   //   return element.type === 'Image' ? element as ImageElement : undefined;
    //   // };

    //   // Decrementing the indices for retreating the image
    //   const newLiveImageIndex = (currentLiveImageIndex - 1 + pageElements.length) % pageElements.length;
    //   const liveImage = getImageElement(pageElements, newLiveImageIndex);
    //   const prevImage = getImageElement(pageElements, (newLiveImageIndex - 1 + pageElements.length) % pageElements.length);
    //   const nextImage = getImageElement(pageElements, (newLiveImageIndex + 1) % pageElements.length);

    //   if (state.projects[status]) {
    //     state.projects[status]![projectIndex] = {
    //       ...project,
    //       liveImage,
    //       prevImage,
    //       nextImage,
    //     };
    //   }
    // },

    resetLiveElements(state, action: PayloadAction<{ status: ProjectStatus }>) {
      const { status } = action.payload;
      const projects = state.projects[status];
      if (!projects) { return; }
      projects.forEach((project) => {
        const { liveElement, originalLiveElement, originalNextImage, originalPrevImage } = project;
        if (liveElement && originalLiveElement) {
          project.liveElement = originalLiveElement;
          originalNextImage && (project.nextImage = originalNextImage);
          originalPrevImage && (project.prevImage = originalPrevImage);
        }
      });
    },
  },
});

export const getSingleProjectById = (state: RootState, projectId: string | number) => {
  if (typeof projectId === 'string') {
    projectId = parseInt(projectId);
  }
  let project: ProjectWithUI | null;
  const { projects } = state.projects;
  const allProjects = Object.values(projects).flat();
  project = allProjects.find((project) => project.id === projectId) || null;
  return ( project );
}

export const getProjectElementById = (state: RootState, elId: number) => {
  let projectElement: PageElement | null;
  const { projects } = state.projects;
  const allProjects = Object.values(projects).flat();
  const allPageElements = allProjects.reduce((acc, project) => {
    project.pageElements.forEach(pe => acc.push(pe));
    return acc; // Return the accumulator
  }, [] as PageElement[]); // Initialize the accumulator as an empty array of PageElement
  projectElement = allPageElements.find((element) => element.id === elId) || null;
  
  return ( projectElement );
}

export const getLiveElement = (state: RootState, projectId: number) => {
  let liveElement: ImageElement | SuiteElement | TextBlock | null;
  const { projects } = state.projects;
  const allProjects = Object.values(projects).flat();
  const project = allProjects.find((project) => project.id === projectId);
  if (project && project.liveElement) {
    liveElement = project.liveElement;
  } else {
    liveElement = null;
  }
  
  return ( liveElement );
}

export const getLiveImage = (state: RootState, projectId: number) => {
  let liveImage: ImageElement | null;
  const { projects } = state.projects;
  const allProjects = Object.values(projects).flat();
  const project = allProjects.find((project) => project.id === projectId);
  if (project && project.liveImage) {
    liveImage = project.liveImage;
  } else {
    liveImage = null;
  }
  
  return ( liveImage );
}

export const getNextImage = (state: RootState, projectId: number) => {
  let nextImage: ImageElement | null;
  const { projects } = state.projects;
  const allProjects = Object.values(projects).flat();
  const project = allProjects.find((project) => project.id === projectId);
  if (project && project.nextImage) {
    nextImage = project.nextImage;
  } else {
    nextImage = null;
  }
  
  return ( nextImage );
}
export const getPrevImage = (state: RootState, projectId: number) => {
  let prevImage: ImageElement | null;
  const { projects } = state.projects;
  const allProjects = Object.values(projects).flat();
  const project = allProjects.find((project) => project.id === projectId);
  if (project && project.prevImage) {
    prevImage = project.prevImage;
  } else {
    prevImage = null;
  }
  
  return ( prevImage );
}

export const { 
  setProjects,
  addProject, 
  advanceProjectElement, 
  retreatProjectElement, 
  // advanceProjectImage, 
  // retreatProjectImage,
  resetLiveElements,
} = projectSlice.actions;
export default projectSlice.reducer;