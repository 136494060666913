export const mockContactContent = {
  studioContact: {
    name: 'Studio',
    content: `
      <p className="medium-break">
        Mark Mahaney
        <br />
        New York / California
      </p>
      <p>
      <a href="mailto:mark@markmahaney.com?subject=Work Inquiry">mark@markmahaney.com</a>
        <br />
        +1 917 415 7280
      </p>
    `
  }, agentContact: {
    name: 'Agent',
    content: `
      <p className="medium-break">
        <a href="#" target="_blank">XYZ Agency</a>
        <br />
        Jane Doe 
      </p>
      <p>
      <a href="">jane@doe.biz</a>
        <br />
        +1 555 666 7777 
      </p>
    `
  }, galleryContact: {
    name: 'Gallery',
    content: `
      <p class='medium-break'>
        <a href="https://www.kominekominekominek.com/mark-mahaney/"target="_blank">Kominek Gallery</a>
      <br>
      Berlin, Germany
      </p>
      <p>
      <a href='mailto:info@kominek-gallery.com?subject=Work Inquiry: Mark Mahaney'>info@kominek-gallery.com</a>
        <br>
      </p>
    `
  }, commercialClients: {
    name: 'Commercial Clients',
    content: `
      <p className="long-list">
        Nike, Apple, Levis, IBM, Google, Microsoft, Chase, Square, Brex, Johnson &amp; Johnson, AirBNB, Expedia, Okta, 3M, HP, Herman Miller, Ikea, Arper, Maharam, Cuyana, Ring Central, Droga, Ogilvy, Wieden + Kennedy, DDB Paris, 2x4 Design, Wolff Olins, VSA Partners, Base Design, Winkreative, BBDO, Addison, Manual, IDW, The New Company, Godfrey Dadich Partners, Landscape, Cinco, Bureau Borsche
      </p>
    `
  }, editorialClients: {
    name: 'Editorial Clients',
    content: `
      <p className="long-list">
        M Le Monde, Sixteen Journal, Document Journal, The New Yorker, Time, The Travel Almanac, GQ, WSJ Magazine, Süddeutsche Zeitung, The New York Times Magazine, Vanity Fair, Wallpaper*, Twin, California Sunday Magazine, The Wire, The Fader, GQ Style, Zeit, The British Journal of Photography, Wired, Surface, Apartamento, The Gourmand, Wax Magazine, Dwell, Bloomberg Businessweek, Port, A Magazine Curated By, Monocle, The Guardian Weekend, London Telegraph Sunday Magazine
      </p>
    `
  }, combinedClients: {
    name: 'Recent Collaborations',
    content: `
      <p className="long-list">
        Nike, Apple, Levis, IBM, Google, Microsoft, Chase, AirBNB, Herman Miller, Ikea, Ogilvy, Wieden + Kennedy, Wolff Olins, Bureau Borsche,
        M Le Monde, Sixteen Journal, Document Journal, The New Yorker, Time, The Travel Almanac, GQ, WSJ Magazine, Süddeutsche Zeitung, The New York Times Magazine, Vanity Fair, Wallpaper*, The Wire, The Fader, GQ Style, Zeit, The British Journal of Photography, Wired, Surface, Apartamento, Wax Magazine, Dwell, Bloomberg Businessweek
      </p>
    `
  }, commercialClientsShort: {
    name: 'Select Commercial Clients',
    content: `
      <p className="long-list">
        Nike, Apple, Levis, IBM, Google, Microsoft, Chase, Square, Brex, Johnson &amp; Johnson, AirBNB, Expedia, Okta, 3M, HP, Herman Miller, Ikea, Arper, Maharam, Cuyana 
      </p>
    `
  }, editorialClientsShort: {
    name: 'Recent Editorial Collaborations',
    content: `
      <p className="long-list">
        M Le Monde, Sixteen Journal, Document Journal, The New Yorker, Time, The Travel Almanac, GQ, WSJ Magazine, Süddeutsche Zeitung, The New York Times Magazine, Vanity Fair, Wallpaper*
      </p>
    `
  },
}

export default mockContactContent;