import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { ContactSection } from '../types/_base'
import { setInfo } from '../features/infoSlice';

const infoApiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/info`;

export const infoApi = createApi({
  reducerPath: 'infoApi',
  baseQuery: fetchBaseQuery({ baseUrl: infoApiEndpoint }),
  endpoints: (builder) => ({
    getInfo: builder.query<ContactSection[], null>({
      query: (status) => ``,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: contactSections } = await queryFulfilled;
          if (contactSections) {
            console.log(contactSections)
            dispatch(setInfo({ contactSections }));
          } else {
            console.log("Did not get contactSections")
            console.log(contactSections)
          }
        } catch (error) {
          // Handle error
        }
      },
    }),
  }),
});


export const { 
  // useGetQuery, 
  useGetInfoQuery, 
} = infoApi 