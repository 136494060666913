import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useGetInfoQuery } from '../services/info';
import { ProjectStatus, Project, PageElement, ContactSection } from '../types/_base';

export const useInfoFetch = () => {
  const dispatch = useAppDispatch();
  const contactSections: ContactSection[] = useAppSelector(state => state.info.contactSections) || [];
  // const pageElements: Array<PageElement> = projects.reduce((acc, project) => {
  //   project.pageElements.forEach(pe => acc.push(pe));
  //   return acc; // Return the accumulator
  // }, [] as PageElement[]); // Initialize the accumulator as an empty array of PageElement
  const { data, error, isLoading, refetch } = useGetInfoQuery(null, {
    skip: contactSections.length > 0,
  });
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  useEffect(() => {
    if (contactSections.length === 0 && !hasFetched && !isLoading && !error) {
      refetch();
      setHasFetched(true);
    }
  }, [contactSections, refetch, hasFetched, isLoading, error]);

  return { contactSections, data, error, isLoading };
};
