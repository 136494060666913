import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useGetProjectsQuery } from '../../services/projects'

import { ProjectStatus } from '../../types/_base'
import { 
  Project, PageElement, ImageElement, SuiteElement, TextBlock, ImageFileParts 
} from '../../types/_base'

import { useProjectsFetch } from '../../hooks/useProjectsFetch';
import { useProjectsFetchMulti } from '../../hooks/useProjectsFetchMulti';
import { 
  getLiveElement, advanceProjectElement, retreatProjectElement, getNextImage, getPrevImage 
} from '../../features/projectSlice';

import Suite from '../../components/Suite';
import AppImage from '../../components/AppImage';
import { LoadingIndicator, ErrorDisplay } from '../../components/SpecialStatusIndicators';

type EvenOrOdd = 'even' | 'odd';
type Rectangle = { width: number, height: number };

export const PlaceholderImage = () => {
  return (
    <img src="data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw==" />
  )
}

export const imageFileUrl = (props: { imgFile: ImageFileParts }) => {
  const { prefix, suffix } = props.imgFile;
  const url = `${prefix}h1200_${suffix}`;
  return (url)
}

type ProjectImageProps = {
  image: ImageElement, 
  availablePixels: Rectangle, 
  lazyLoad: boolean,
  onLoadCallback?: () => void, // Optional onLoad callback
}

export const ProjectImage = (props: ProjectImageProps) => {
  const { image, lazyLoad, onLoadCallback } = props;
  const { prefix, suffix } = image.file;
  const url = imageFileUrl({ imgFile: image.file });
  const imgStyle = {
    height: "100%",
  }

  return (
    <AppImage 
      prefix={prefix} 
      suffix={suffix}
      aspectRatio={image.aspectRatio}
      verticalPixels={props.availablePixels.height}
      horizontalPixels={props.availablePixels.width} 
      style={imgStyle} 
      lazyLoad={lazyLoad}
      onLoadCallback={onLoadCallback}
    />
  )
}

export const ProjectTextBlock = (props: { textBlock: TextBlock }) => {
  const { textBlock } = props;
  const content = textBlock.body || '' as string;
  const style = {
    padding: '0 6rem',
    maxWidth: '64rem',
  }
  return (
    <div
      style={style} 
      dangerouslySetInnerHTML={{ __html: content }} 
    />
  )
}

export const ClientColonTitle = (props: { project: Project }) => {
  const { client, title } = props.project;

  const output = [client, title].filter(str => str && str.trim() !== '').join(': ').toUpperCase();
  
  return ( <>{output}</> )
}

export const BigGridProject = (props: { project: Project, evenOrOdd: EvenOrOdd }) => {
  const dispatch = useAppDispatch();
  
  const { project, evenOrOdd } = props;

  const projectId = project.id;
  const projectStatus = project.status;

  const liveElement: PageElement | null = useAppSelector(state => getLiveElement(state, project.id));
  let centerPanelContent: JSX.Element | null = null;
  let captionContent: string | null = null;
  
  const viewPortWidth  = useAppSelector(state => state.device.viewPortWidth);
  const viewPortHeight = useAppSelector(state => state.device.viewPortHeight);
  
  const leftImage = useAppSelector(state => getPrevImage(state, project.id));
  const rightImage = useAppSelector(state => getNextImage(state, project.id));

  const availablePixels = { width: viewPortWidth * 0.81, height: viewPortHeight * 0.6667 };

  const [centerImageLoaded, setCenterImageLoaded] = useState<boolean>(false);
  const registerCenterImageLoad = () => setCenterImageLoaded(true);
  
  if (liveElement) {
    if (liveElement.type === 'Image') {
      centerPanelContent = <ProjectImage image={liveElement as ImageElement} availablePixels={availablePixels} lazyLoad={true} onLoadCallback={registerCenterImageLoad} />
      captionContent = liveElement.body || null;
      // captionContent = `Center image loaded: ${centerImageLoaded}`;
    } else if (liveElement.type === 'Suite') {
      console.log('liveElement is a Suite')
      centerPanelContent = <Suite suite={liveElement as SuiteElement} containerShape='recent' /> 
      captionContent = liveElement.body || null;
    } else if (liveElement.type === 'TextBlock') {
      console.log('liveElement is a TextBlock')
      centerPanelContent = <ProjectTextBlock textBlock={liveElement as TextBlock} /> 
    } else {
      console.log('WARNING: liveElement is something else: ', liveElement.type)
    }
  }

  const advanceClickHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    dispatch(advanceProjectElement({ projectId: projectId, status: projectStatus }));
  }
  const retreatClickHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    dispatch(retreatProjectElement({ projectId: projectId, status: projectStatus }));
  }
  
  return (
    <div 
      className={`rail ${evenOrOdd} relative`} 
      id={`project-${projectId}-rail`} 
    >
      <div className='left-panel panel'>
        {leftImage ? <ProjectImage image={leftImage} availablePixels={availablePixels} lazyLoad={!centerImageLoaded} /> : <PlaceholderImage />}
      </div>
    
    <div className='center-panel panel'>
      <div className='center-panel-inner-wrapper'>
        {liveElement && centerPanelContent}

        {/* <div className='suite-wrapper'></div> */}
        <div className='caption allow-select'>
          { captionContent ? captionContent : <ClientColonTitle project={project} /> }
        </div>
      </div> 
    </div>

    <div className='right-panel panel'>
      {rightImage ? <ProjectImage image={rightImage} availablePixels={availablePixels}  lazyLoad={centerImageLoaded} /> : <PlaceholderImage />}
    </div>
    
    <div className="w-screen absolute flex inset-0 click-catcher">
      <div className="h-full w-1/3 left-side" onClick={retreatClickHandler}></div> 
      <div className="h-full w-2/3 right-side" onClick={advanceClickHandler}></div> 
    </div>
  </div>
  )
}

export const ProjectsView = (props: { projects: Project[] }) => {
  const { projects } = props;
  const hasProjectsToRender = projects && projects.length > 0;
  
  return (
    <>
      {hasProjectsToRender ? projects.map((project, index) => (
        <BigGridProject project={project} key={`project-${project.id}`} evenOrOdd={index % 2 === 1 ? 'odd' : 'even'} />
      )) : 
        <p>Hmmm no projects to render...</p>
      }
    </>
  )
}

const RecentPage = () => {
  const thisStatus = ProjectStatus.recent;
  const otherStatuses = [ProjectStatus.overview, ProjectStatus.archive] 
  const dispatch = useAppDispatch();
  const { projects, hasFetched, pageElements, data, error, isLoading } = useProjectsFetch(thisStatus);
  
  // Fetch additional project statuses
  const multiResults = useProjectsFetchMulti(otherStatuses);

  useEffect(() => {
    console.log('multiResults.hasFetched: ', multiResults.hasFetched);
  }, [multiResults.hasFetched]);
  // End fetch additional project statuses 
  
  const [mostVisibleProjectId, setMostVisibleProjectId] = useState<number | null>(null);

  useEffect(() => {
    console.log('RecentPage: useEffect: hasFetched: ', hasFetched)
  }, [hasFetched]);

  useEffect(() => {
    const determineMostVisible = () => {
      let maxVisibility = 0;
      let mostVisibleProject = null;

      projects.forEach(project => {
        const element = document.getElementById(`project-${project.id}-rail`);
        if (element) {
          const rect = element.getBoundingClientRect();
          const visibleArea = Math.max(0, rect.bottom) - Math.max(0, rect.top);
          const totalArea = rect.height;
          const visibility = visibleArea / totalArea;

          if (visibility > maxVisibility) {
            maxVisibility = visibility;
            mostVisibleProject = project.id;
          }
        }
      });

      setMostVisibleProjectId(mostVisibleProject);
    };

    window.addEventListener('scroll', determineMostVisible);
    return () => window.removeEventListener('scroll', determineMostVisible);
  }, [projects]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (mostVisibleProjectId !== null) {
        if (e.key === 'ArrowRight') {
          dispatch(advanceProjectElement({ projectId: mostVisibleProjectId, status: thisStatus }));
        } else if (e.key === 'ArrowLeft') {
          dispatch(retreatProjectElement({ projectId: mostVisibleProjectId, status: thisStatus }));
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [mostVisibleProjectId, dispatch]);

  if (isLoading) { 
    return <LoadingIndicator /> 
  } else if (error) {
    console.log(error); 
    return <ErrorDisplay errorMessage={'Whoops, something went wrong!'} /> 
  } else {
    return (
      <div className='home-outer-wrapper'>
        <ProjectsView projects={projects} />
      </div>
    )
  }
}

export default RecentPage