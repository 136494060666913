import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useGetProjectsQuery } from '../../services/projects'

import { ProjectStatus, GridSize } from '../../types/_base'
import { 
  Project, PageElement, ImageElement, SuiteElement, TextBlock, ImageFileParts 
} from '../../types/_base'

import AppImage from '../../components/AppImage'; 
import Lightbox from '../../components/Lightbox';

import { openLightbox, setActiveElement } from '../../features/lightboxSlice';
import { useProjectsFetch } from '../../hooks/useProjectsFetch'; 
import { useProjectsFetchMulti } from '../../hooks/useProjectsFetchMulti'; 
import { getProjectElementById } from '../../features/projectSlice';

import { LoadingIndicator, ErrorDisplay } from '../../components/SpecialStatusIndicators';

export const GridImage = (props: { image: ImageElement, size: GridSize, lightboxElId: number }) => {
  const dispatch = useAppDispatch();
  
  const { image, lightboxElId } = props;
  
  const lightboxEl = useAppSelector(state => getProjectElementById(state, lightboxElId)) as PageElement;

  const { prefix, suffix } = image.file;

  const clickHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    if (!lightboxEl?.id) {
      console.log('no lightboxEl');
      console.log('lightboxElId: ' + lightboxElId)
      return;
    }
    dispatch(setActiveElement({ pageElementId: lightboxEl.id }));
    dispatch(openLightbox());
  }

  return (
    <div className='thumb image-thumb' onClick={clickHandler}>
      <AppImage prefix={prefix} suffix={suffix} aspectRatio={image.aspectRatio} verticalPixels={192} pixelFraction={0.8} />
      <div className='placeholder'></div>
    </div>
  )
}

export const GridElement = (props: { el: ImageElement, size: GridSize }) => {
  const { el, size } = props;
  const lightboxElId = el.suiteId ? el.suiteId : el.id;
  if (el.type === "Image") {
    return(<GridImage image={el as ImageElement} size={size} lightboxElId={lightboxElId} />)
  } else {
    return(<></>)
  }
}

const GridPage = (props: { activeProjectStatus: ProjectStatus, additionalProjectStatuses: ProjectStatus[], gridSize: GridSize }) => {
  const { activeProjectStatus, additionalProjectStatuses, gridSize } = props
  const { projects, images, pageElements, data, error, isLoading, hasFetched } = useProjectsFetch(activeProjectStatus);
  
  // Fetch additional project statuses
  const multiResults = useProjectsFetchMulti(additionalProjectStatuses);

  useEffect(() => {
    console.log('multiResults.hasFetched: ', multiResults.hasFetched);
  }, [multiResults.hasFetched]);
  // End fetch additional project statuses 

  if (isLoading) { 
    return <LoadingIndicator /> 
  } else if (error) {
    console.log(error); 
    return <ErrorDisplay errorMessage={'Whoops, something went wrong!'} /> 
  } else {
    return (
      <>
        <div className={`grid-wrapper ${gridSize}-grid`}>
          {images.map((imageElement, i) => (
            <GridElement el={imageElement} size={gridSize} key={i} />
          ))}
          {/* {pageElements.map((pageElement, i) => (
            <GridElement el={pageElement} size={gridSize} key={i} />
          ))} */}
        </div>
        <Lightbox pageElements={pageElements} />
      </>
    )
        }
}

export default GridPage